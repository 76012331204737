import React from "react";
import theme from "theme";
import { Theme, Text, Section, Em, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PrimeServe
			</title>
			<meta name={"description"} content={"Наша місія в PrimeServe полягає в тому, щоб покращити ваш досвід тенісу, підтримуючи виняткові стандарти для наших кортів і послуг."} />
			<meta property={"og:title"} content={"PrimeServe"} />
			<meta property={"og:description"} content={"Наша місія в PrimeServe полягає в тому, щоб покращити ваш досвід тенісу, підтримуючи виняткові стандарти для наших кортів і послуг."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-1.jpg?v=2024-06-12T09:15:20.790Z) bottom/cover"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Ласкаво просимо до PrimeServe
					</Text>
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Відкрийте для себе гру разом з нами
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Ласкаво просимо до PrimeServe, ідеальне місце для любителів тенісу, яким потрібна якість і сервіс.
					</Em>
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					{" "}У PrimeServe кожен гравець знаходить гостинну атмосферу та першокласні зручності, що гарантує преміум тенісний досвід кожного разу, коли ви виходите на наші корти.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09:15:20.786Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="300px"
					object-position="bottom"
					srcSet="https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0a8fdcae00021e33fea/images/2-2.jpg?v=2024-06-12T09%3A15%3A20.786Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					У PrimeServe ми прагнемо розвивати любов до тенісу в закладі, де наголошується на задоволенні гравців і досконалості корту.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Наші корти створені, щоб забезпечити найкращу зручність і комфорт, незалежно від того, чи ви досвідчений гравець, чи тільки починаєте. Регулярне технічне обслуговування та відданість якості роблять кожен матч на PrimeServe незабутнім.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Чому варто вибрати PrimeServe?
			</Text>
		</Section>
		<Section padding="0px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Корти найвищої якості
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наші корти відповідають найвищим стандартам, щоб забезпечити ідеальні умови для гри.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Увага до деталей
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Від сіток професійного рівня до добре обладнаного середовища, кожна деталь розроблена з урахуванням вашої гри.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Зосередьтеся на комфорті
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Насолоджуйтесь просторими місцями для сидіння, затіненими зонами та можливістю освіжити, щоб ваш час поза кортом був таким же приємним, як і ваша гра.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline5"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Наша місія в PrimeServe полягає в тому, щоб покращити ваш досвід тенісу, підтримуючи виняткові стандарти для наших кортів і послуг.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Ми захоплюємося тенісом і присвячуємо себе створенню середовища, де кожен гравець може процвітати та отримувати задоволення від гри.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6668b0a8fdcae00021e33fea/images/1-4.jpg?v=2024-06-12T09:15:20.800Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Приєднуйтесь до нас у PrimeServe і грайте там, де грають найкращі гравці
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="70%"
				>
					Забронюйте свій корт у PrimeServe сьогодні та дізнайтеся, чому ми є улюбленим місцем для тенісистів, яким потрібна якість і стабільність.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});